<template>
  <div
    class="submit-bug-main"
    :class="$vuetify.breakpoint.smAndDown ? '' : 'pa-13'"
  >
    <v-row>
      <v-col cols="12" lg="4" md="6">
        <h2
          class="mb-4"
          :class="$vuetify.breakpoint.smAndDown ? 'text-center' : ''"
        >
          Having an issue?
        </h2>
        <div class="mb-10 mt-5">
          <div class="mb-3">
            <span class="font-weight-bold">Step 1: </span> Summarise the issues
            you're seeing in your app
          </div>
          <v-textarea
            outlined
            dense
            label="Bug Description"
            hide-details
            v-model="data.description"
            placeholder="Please be as detailed as possible, tell us when the
bug occurs and what you were trying to do when it
happened so we can replicate the bug."
          ></v-textarea>
        </div>

        <div class="mb-4">
          <div class="mb-3">
            <span class="font-weight-bold">Step 2: </span> Upload a screenshot
            of the error you are seeing (max 500mb)
          </div>

          <v-file-input
            v-model="files"
            color="primary"
            counter
            label="Image input"
            multiple
            placeholder="Select your images"
            prepend-icon="mdi-file-image"
            outlined
            :show-size="1000"
            :rules="required"
            accept="image/png, image/jpeg, image/bmp"
            @change="handleImageUpload"
            dense
          >
            <template v-slot:selection="{ index, text }">
              <v-chip v-if="index < 2" color="primary" dark label small>
                {{ text }}
              </v-chip>

              <span
                v-else-if="index === 2"
                class="text-overline grey--text text--darken-3 mx-2"
              >
                +{{ files.length - 2 }} image(s)
              </span>
            </template>
          </v-file-input>
        </div>

        <v-btn
          class="text-capitalize"
          color="primary"
          :block="$vuetify.breakpoint.smAndDown"
          :disabled="isSaving"
          :loading="isSaving"
          @click="bugSubmit()"
          >Submit</v-btn
        >
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  data() {
    return {
      files: [],
      data: {
        description: null,
        images: [],
      },
      isSaving: false,
      rules: {
        required: (value) => !!value || "Required.",
      },
      required: [(v) => !!v || "Required"],
    };
  },
  methods: {
    ...mapActions("bugs", ["createBug"]),
    validate() {
      if (!this.data.description) {
        globalErrorHandler("", "Description is required.");
        return false;
      }
      return true;
    },
    handleImageUpload(file) {
      if (file) {
        if (file.length > 0) {
          file.forEach((item) => {
            console.log("image:::::", item.name);
            this.data.images.push(item.name);
          });
        }
        console.log("data:::::", this.data);
      }
    },
    bugSubmit: _.debounce(function (item) {
      this.submitBug(this.data);
    }, 300),
    async submitBug(data) {
      if (!this.validate()) return;

      this.isSaving = true;
      try {
        await this.createBug(data);
        this.$nextTick(() => {
          this.data = {
            description: null,
            images: [],
          };
          this.files = [];
        });
        globalMessageHandler("Successfully submitted!");
        this.isSaving = false;
      } catch (error) {
        globalErrorHandler(error, "Unable to submit.");
        this.isSaving = false;
      } finally {
        this.isSaving = false;
      }
    },
  },
};
</script>
